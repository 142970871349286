import { Controller } from "@hotwired/stimulus"

/**
 * Enable or disable a given input element by calling enable or disable
 * actions. Useful for toggling form elements by checkboxes or radio buttons.
 */
export default class extends Controller {
  static targets = ["input"]
  inputTarget: HTMLInputElement

  enable() {
    this.inputTarget.disabled = false
  }

  disable() {
    this.inputTarget.disabled = true
  }
}
