import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["btn", "options"]

  declare readonly btnTarget: HTMLElement
  declare readonly optionsTarget: HTMLElement

  connect() {
    $(this.optionsTarget).hide().removeClass("hidden")
  }

  toggle() {
    $(this.optionsTarget).toggle("fast")
  }

  close(e) {
    if (
      $(e.target).closest(this.element).length === 0 &&
      $(this.optionsTarget).is(":visible")
    ) {
      this.toggle()
    }
  }
}
