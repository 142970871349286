/**
 * Test whether the current user is on the dashboard page, so we can
 * decide to add an activity marker to it.
 *
 * @returns bool
 */
function onDashboard() {
  return window.location.pathname === "/"
}

/**
 * Test whether the current user is on a particular dialog page, so we can
 * decide whether or not to display notifications for it.
 *
 * @param {String} pathname
 * @returns bool
 */
function onDialog(pathname) {
  return window.location.pathname === pathname
}

/**
 * Add a new activity marker to the main menu for the dashboard page.
 */
function addActivityMarkerToMainMenu(data) {
  let count
  if (data.type === "batched_dialog_messages") {
    count = data.messages.length
  } else {
    count = 1
  }
  $("#unread-messages-count").html(
    `<span class="activity-indicator">${count}</span>`,
  )
}

/**
 * For any dialogs listed in the table on the current page, insert an
 * activity marker if the incoming event is about that dialog.
 *
 * @param {object} data
 */
function addMarkersToVisibleDialogs(data) {
  if (data.type === "batched_dialog_messages") {
    $(`table tr[data-dialog-id="${data.dialogId}"]`)
      .find("td:first-child")
      .html('<div class="w-2 h-2 bg-blue rounded-full mx-auto"></div>')
  }
}

/**
 * Insert a notice into the page indicating the the content is out of date and the user should
 * refresh to get the latest content. We do not just refresh for the user to avoid losing relevant
 * local state.
 */
function addRefreshNotice(data) {
  if ($(`table tr[data-dialog-id="${data.dialogId}"]`).length > 0) {
    return
  }

  $(
    "<div class='banner banner--yellow'>The current page is out of date. Refresh to see the latest activity.</div>",
  )
    .on("click", function () {
      window.location.reload()
    })
    .insertBefore("table.table")
}

/**
 * Display a notification using the web notifications API if the user
 * has given us permission to do so.
 *
 * @param {object} data
 */
function displayWebNotification(data) {
  if (Notification.permission !== "granted") {
    console.log("Skipping web notification, no permission given")
    return
  }

  if (onDialog(data.url)) {
    console.log("Skipping web notifications for currently displayed dialog")
    return
  }

  if (data.type == "batched_dialog_messages") {
    if (data.messages.length === 1) {
      const msg = data.messages[0]
      notify(`New message from ${msg.sender}`, msg.body, data.url)
    } else if (data.messages.length > 1) {
      notify(
        `New activity`,
        `There are ${data.messages.length} new messages.`,
        data.url,
      )
    }
  }
}

/**
 * Display an operating system-native notification using the web notifications API.
 *
 * @param {String} title to display in the notification
 * @param {String} body to show below the title
 * @param {String} url to take user to when clicking the notification
 */
function notify(title, body, url) {
  const notification = new Notification(title, {
    body: body,
    icon: "/assets/unloq-icon.png",
  })
  notification.onclick = () => (window.location = url)
}

/**
 * Request permission to use the notifications API. Useful to call on
 * page load so the user can set this preference up front.
 */
export function requestPermission() {
  Notification.requestPermission()
}

/**
 * Take incoming activity data and display it as notifications depending
 * on its contents.
 *
 * @param {object} data
 */
export function process(data) {
  console.log("processing notification input", data)
  if (!onDashboard()) {
    addActivityMarkerToMainMenu(data)
  } else {
    addMarkersToVisibleDialogs(data)
    addRefreshNotice(data)
  }
  displayWebNotification(data)
}
