/**
 * Assemble a `FormData` object to upload a `file` via
 * an `XMLHttpRequest`.
 */
function createFormData(file: File, parent_type: string, parent_id: string) {
  const data = new FormData()
  data.append("parent_type", parent_type)
  data.append("parent_id", parent_id)
  data.append("file", file)
  data.append("Content-Type", file.type)
  return data
}

/**
 * Create a new XMLHttpRequest object for use with `jQuery.ajax`,
 * with an added event listener for the `progress` event for uploads.
 */
function xhrWithProgress(fun: (ProgressEvent) => void) {
  return function () {
    const xhr = new XMLHttpRequest()
    xhr.upload.addEventListener("progress", fun, false)
    return xhr
  }
}

function calculateUploadPercentage(e) {
  return (e.loaded / e.total) * 100
}

export function uploadFile(url, attachment) {
  const file = attachment.file
  const data = createFormData(
    file,
    attachment.parent_type,
    attachment.parent_id,
  )

  return jQuery
    .ajax({
      url: url,
      method: "post",
      data: data,
      processData: false,
      contentType: false,
      xhr: xhrWithProgress((e) => {
        // Check if it's a Trix attachment object
        if (typeof attachment.setUploadProgress === "function") {
          attachment.setUploadProgress(calculateUploadPercentage(e))
        }
      }),
    })
    .done((response) => {
      // Check if it's a Trix attachment object
      if (typeof attachment.setAttributes === "function") {
        attachment.setAttributes({
          url: response.url,
          href: response.href,
          removeUrl: response.removeUrl,
        })
      } else {
        location.reload()
      }
    })
    .fail((e) => {
      console.error("Error when uploading the file", e)
    })
}

export function destroyUploadedFile(url) {
  jQuery
    .ajax({
      url: url,
      method: "delete",
    })
    .fail((e) => {
      console.error("Error when removing uploaded file", e)
    })
}
