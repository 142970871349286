import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "program",
    "learningsteps",
    "learningstepsWrapper",
    "typeOf",
    "duration",
    "explanation",
  ]
  programTarget: HTMLInputElement
  learningstepsTarget: HTMLInputElement
  learningstepsWrapperTarget: Element
  typeOfTarget: HTMLSelectElement
  durationTarget: HTMLInputElement
  explanationTarget: HTMLElement

  connect() {
    this.changeProgram()
    this.toggleDuration()
  }

  changeProgram() {
    $.ajax({
      url: "/api/learning_steps.json?program_id=" + this.programTarget.value,
      success: (data) => {
        this.refreshDropdownValues(data)
      },
      error: function () {
        console.log("Error retrieving learning steps")
      },
    })
  }

  refreshDropdownValues(data) {
    this.learningstepsTarget.innerHTML = ""

    if (data.length == 0 || data.length == undefined) {
      $(this.learningstepsTarget).removeAttr("required")
      this.learningstepsWrapperTarget.classList.add("hidden")
      return
    }

    for (let i = 0; i < data.length; i++) {
      const learningStep = data[i]
      $(this.learningstepsTarget).attr("required", "true")
      this.learningstepsTarget.innerHTML +=
        '<option value="' +
        learningStep.id +
        '">' +
        learningStep.presentation +
        "</option>"
    }

    this.learningstepsWrapperTarget.classList.remove("hidden")
  }

  toggleDuration() {
    const selectedValue = parseInt(this.typeOfTarget.value)

    // 8 means cancellation_legit in the COMMENT_TYPES constant

    if (selectedValue === 8) {
      this.durationTarget.disabled = true
      $(this.durationTarget).css("background-color", "#e8e8e8")
      this.durationTarget.value = ""
      this.explanationTarget.classList.remove("hidden")
    } else {
      this.durationTarget.disabled = false
      $(this.durationTarget).css("background-color", "#fff")
      this.explanationTarget.classList.add("hidden")
    }
  }
}
