import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "results", "other", "spinner"]

  declare readonly inputTarget: Element
  declare readonly resultsTarget: Element
  declare readonly otherTarget: Element
  declare readonly spinnerTarget: Element

  fetch() {
    if ($(this.inputTarget).val() === "") {
      this.resultsTarget.classList.add("hidden")
      this.otherTarget.classList.add("hidden")
      this.spinnerTarget.classList.add("hidden")
    } else {
      this.startRequest()
      $.ajax({
        url: this.data.get("url") + "?locale=" + this.data.get("locale"),
        data: { email: $(this.inputTarget).val() },
        dataType: "script",
      }).always(() => this.endRequest())
    }
  }

  startRequest() {
    this.spinnerTarget.classList.remove("hidden")
  }

  endRequest() {
    this.spinnerTarget.classList.add("hidden")
  }
}
