import * as Trix from "trix/dist/trix"
import { Controller } from "@hotwired/stimulus"

const lang = Trix.config.lang

export default class extends Controller {
  static targets = ["editor"]
  editorTarget: HTMLElement

  connect() {
    const basicButtons = `<span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
      <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${lang.bold}</button>
      <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
    </span>`
    this.editorTarget.innerHTML = basicButtons
  }
}
