const slice = [].slice

function getHexColor(color) {
  if (!color) {
    return ""
  }
  if (/^#/.test(color)) {
    return color
  }
  const rgbValues = getRGBValues(color)
  const hexValues = rgbValues.map(numberToHex)
  return "#" + hexValues.join("")
}

function numberToHex(number) {
  return `0${number.toString(16)}`.slice(-2).toUpperCase()
}

function getRGBValues(color) {
  let b, canvas, context, data, g, match, r
  if ((match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/i))) {
    ;[r, g, b] = slice.call(match, -3)
  } else {
    canvas = document.createElement("canvas")
    canvas.height = canvas.width = 1
    context = canvas.getContext("2d")
    context.fillStyle = color
    context.fillRect(0, 0, 1, 1)
    ;({ data } = context.getImageData(0, 0, 1, 1))
    ;[r, g, b] = data
  }
  return [r, g, b].map(Number)
}

export function createColorParser() {
  return function (element) {
    let color, styleColor
    while (element && element.tagName !== "TRIX-EDITOR") {
      if ((styleColor = element.style[this.styleProperty])) {
        if ((color = getHexColor(styleColor))) {
          return color
        }
      }
      element = element.parentElement
    }
  }
}
