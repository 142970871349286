import app from "./app_helper"
import { embedCode } from "../helpers/video_helper"

class DialogHelper {
  get token() {
    return this.collection.data("dialog")
  }

  get collection() {
    return $("[data-dialog]")
  }

  highlightMessages() {
    this.collection
      .find(".message")
      .removeClass("message--left message--right")
      .addClass("message--left")
      .filter(`[data-user-id=${app.currentUserId}]`)
      .addClass("message--right")
      .removeClass("message--left")
  }

  scrollToBottom() {
    $("html,body").animate(
      { scrollTop: this.collection.prop("scrollHeight") },
      1,
    ) // chrome
    $("#dialog_1").animate(
      { scrollTop: this.collection.prop("scrollHeight") },
      3,
    ) // safari
  }

  updateLastSeenAt() {
    const pattern = /dialogs\/\w+\/mark_read$/
    if (pattern.test(document.location.href)) {
      $.ajax({
        url: `${document.location.href}/mark_read`,
        type: "POST",
      })
    }
  }

  catchAndInlineEmbeds() {
    this.collection
      .find(
        ".message .attachment--preview:has(a[href*='youtube.com']), .message .attachment--preview:has(a[href*='youtu.be']), .message .attachment--preview:has(a[href*='vimeo.com']), .message .attachment--preview:has(a[href*='ted.com'])",
      )
      .on("click", function (e) {
        e.preventDefault()
        const attrs = JSON.parse(this.dataset.trixAttachment)
        const id = attrs.externalId
        const url = attrs.href
        $(this).find("a").replaceWith(embedCode(id, url))
      })
  }

  embedVideos() {
    $(
      ".attachment--preview:has(a[href*='youtube.com']), .attachment--preview:has(a[href*='youtu.be']), .attachment--preview:has(a[href*='vimeo.com']), .attachment--preview:has(a[href*='ted.com'])",
    ).each(function () {
      const attrs = JSON.parse(this.dataset.trixAttachment)
      const id = attrs.externalId
      const url = attrs.href
      $(this).replaceWith(embedCode(id, url))
    })
  }
}

export default new DialogHelper()
