import { Controller } from "@hotwired/stimulus"
import * as toastr from "toastr"

export default class extends Controller {
  static targets = ["messages"]
  messagesTarget: HTMLElement

  connect() {
    const messages = JSON.parse(this.messagesTarget.innerHTML)

    messages.forEach((message) => {
      toastr[message[0]](message[1])
    })
  }
}
