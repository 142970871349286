import { Controller } from "@hotwired/stimulus"
import { uploadFile } from "../helpers/upload_helper"

export default class extends Controller {
  addAttachment() {
    const fileInput = $("#menu-upload")
    const uploadUrl = fileInput.data("upload-upload-url")
    const parentType = fileInput.data("upload-parent-type")
    const parentId = fileInput.data("upload-parent-id")

    fileInput.on("change", function (this: HTMLInputElement) {
      for (let i = 0, j = this.files.length; i < j; i++) {
        uploadFile(uploadUrl, {
          file: this.files[i],
          parent_type: parentType,
          parent_id: parentId,
        })
      }
    })
    fileInput.click()
  }
}
