import { Controller } from "@hotwired/stimulus"
import * as toastr from "toastr"

export default class extends Controller {
  static targets = ["source", "url", "icon", "check"]

  declare readonly sourceTarget: HTMLInputElement
  declare readonly urlTarget: HTMLInputElement
  declare readonly iconTarget: Element
  declare readonly checkTarget: Element

  retrieve(event) {
    event.preventDefault()

    navigator.clipboard.writeText(this.urlTarget.value)

    $.get(this.sourceTarget.value)
      .done((data) => {
        this.swapIcon()
        toastr.success(data.success)
      })
      .fail(function () {
        toastr.error("An unknown error occurred.")
      })
  }

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.urlTarget.value)
    this.swapIcon()
  }

  private swapIcon() {
    this.iconTarget.classList.add("hidden")
    this.checkTarget.classList.remove("hidden")
  }
}
