;(() => {
  var e = {
      294: () => {
        ;(up.browser.loadPage = function (...e) {
          return (
            up.migrate.deprecated(
              "up.browser.loadPage()",
              "up.network.loadPage()"
            ),
            up.network.loadPage(...e)
          )
        }),
          (up.browser.isSupported = function (...e) {
            return (
              up.migrate.deprecated(
                "up.browser.isSupported()",
                "up.framework.isSupported()"
              ),
              up.framework.isSupported(...e)
            )
          })
      },
      390: () => {
        up.Config.prototype.patch = function (e) {
          let u = e.bind(this, this)
          u(), document.addEventListener("up:framework:reset", u)
        }
      },
      700: () => {
        ;(up.element.first = function (...e) {
          return (
            up.migrate.deprecated("up.element.first()", "up.element.get()"),
            up.element.get(...e)
          )
        }),
          (up.element.createFromHtml = function (...e) {
            return (
              up.migrate.deprecated(
                "up.element.createFromHtml()",
                "up.element.createFromHTML()"
              ),
              up.element.createFromHTML(...e)
            )
          }),
          (up.element.remove = function (e) {
            return (
              up.migrate.deprecated("up.element.remove()", "Element#remove()"),
              e.remove()
            )
          }),
          (up.element.matches = function (e, u) {
            return (
              up.migrate.deprecated(
                "up.element.matches()",
                "Element#matches()"
              ),
              e.matches(u)
            )
          }),
          (up.element.closest = function (e, u) {
            return (
              up.migrate.deprecated(
                "up.element.closest()",
                "Element#closest()"
              ),
              e.closest(u)
            )
          }),
          (up.element.replace = function (e, u) {
            return (
              up.migrate.deprecated(
                "up.element.replace()",
                "Element#replaceWith()"
              ),
              e.replaceWith(u)
            )
          }),
          (up.element.all = function (...e) {
            up.migrate.deprecated(
              "up.element.all()",
              "Document#querySelectorAll() or Element#querySelectorAll()"
            )
            const u = e.pop()
            return (e[0] || document).querySelectorAll(u)
          }),
          (up.element.toggleClass = function (e, u, t) {
            up.migrate.deprecated(
              "up.element.toggleClass()",
              "element.classList.toggle()"
            )
            const p = e.classList
            return null == t && (t = !p.contains(u)), t ? p.add(u) : p.remove(u)
          }),
          (up.element.toSelector = function (...e) {
            return (
              up.migrate.deprecated(
                "up.element.toSelector()",
                "up.fragment.toTarget()"
              ),
              up.fragment.toTarget(...e)
            )
          }),
          (up.element.isAttached = function (e) {
            return (
              up.migrate.deprecated(
                "up.element.isAttached()",
                "document.contains(element)"
              ),
              document.contains(e)
            )
          }),
          (up.element.isDetached = function (e) {
            return (
              up.migrate.deprecated(
                "up.element.isDetached()",
                "!document.contains(element)"
              ),
              !up.element.isAttached(e)
            )
          })
      },
      937: () => {
        class DeprecatedCannotCompile extends up.Error {}
        Object.defineProperty(up, "CannotCompile", {
          get: function () {
            return (
              up.migrate.warn(
                'The error up.CannotCompile is no longer thrown. Compiler errors now emit an "error" event on window, but no longer crash the render pass.'
              ),
              DeprecatedCannotCompile
            )
          },
        })
      },
      394: () => {
        up.migrate.renamedPackage("bus", "event"),
          (up.event.nobodyPrevents = function (...e) {
            return (
              up.migrate.deprecated(
                "up.event.nobodyPrevents(type)",
                "!up.emit(type).defaultPrevented"
              ),
              !up.emit(...e).defaultPrevented
            )
          }),
          (up.$on = function (...e) {
            up.migrate.warn(
              "up.$on() has been deprecated. Instead use up.on() with a callback that wraps the given native element in a jQuery collection."
            )
            let u = e.pop()
            return (
              (u.upNativeCallback = function (e, t, p) {
                let o = jQuery(t)
                u.call(o, e, o, p)
              }),
              up.on(...e, u.upNativeCallback)
            )
          }),
          (up.$off = function (...e) {
            up.migrate.deprecated("up.$off()", "up.off()")
            let u = e.pop().upNativeCallback
            return (
              u ||
                up.fail(
                  "The callback passed to up.$off() was never registered with up.$on()"
                ),
              up.off(...e, u)
            )
          })
      },
      729: () => {
        up.migrate.renamedPackage("navigation", "feedback"),
          up.feedback.config.patch(function (e) {
            up.migrate.renamedProperty(e, "navs", "navSelectors")
          })
      },
      305: () => {
        up.form.config.patch(function (e) {
          up.migrate.renamedProperty(e, "fields", "fieldSelectors"),
            up.migrate.renamedProperty(
              e,
              "submitButtons",
              "submitButtonSelectors"
            ),
            up.migrate.renamedProperty(e, "validateTargets", "groupSelectors"),
            up.migrate.renamedProperty(e, "observeDelay", "watchInputDelay")
        }),
          (up.migrate.migratedFormGroupSelectors = function () {
            return up.form.config.groupSelectors.map((e) => {
              let u = e.replace(/:has\((:origin|&)\)$/, "")
              return (
                e !== u &&
                  up.migrate.warn(
                    'Selectors in up.form.config.groupSelectors must not contain ":has(:origin)". The suffix is added automatically where required. Found in "%s".',
                    e
                  ),
                u
              )
            })
          }),
          up.migrate.renamedAttribute("up-observe", "up-watch"),
          up.migrate.renamedAttribute("up-fieldset", "up-form-group"),
          up.migrate.renamedAttribute("up-delay", "up-watch-delay", {
            scope: "[up-autosubmit]",
          }),
          up.migrate.renamedAttribute("up-delay", "up-watch-delay", {
            scope: "[up-watch]",
          }),
          (up.observe = function (...e) {
            if (
              (up.migrate.deprecated("up.observe()", "up.watch()"),
              up.util.isList(e[0]) && e[0].length > 1)
            ) {
              let u = e.shift()
              up.migrate.warn(
                "Calling up.observe() with a list of multiple elements is no longer supported by up.watch()"
              )
              let t = up.util.map(u, (u) => up.watch(u, ...e))
              return up.util.sequence(t)
            }
            return up.watch(...e)
          })
      },
      354: () => {
        const e = up.util
        up.migrate.renamedPackage("flow", "fragment"),
          up.migrate.renamedPackage("dom", "fragment"),
          up.fragment.config.patch(function (e) {
            up.migrate.renamedProperty(e, "fallbacks", "mainTargets")
          }),
          (up.migrate.handleResponseDocOptions = (e) =>
            up.migrate.fixKey(e, "html", "document")),
          up.fragment.config.patch(function (e) {
            let u = () =>
              up.migrate.deprecated(
                "up.fragment.config.matchAroundOrigin",
                "up.fragment.config.match"
              )
            Object.defineProperty(e, "matchAroundOrigin", {
              get: function () {
                return u(), "closest" === this.match
              },
              set: function (e) {
                u(), (this.match = e ? "region" : "first")
              },
            })
          }),
          (up.replace = function (e, u, t) {
            return (
              up.migrate.deprecated(
                "up.replace(target, url)",
                "up.navigate(target, { url })"
              ),
              up.navigate(
                Object.assign(Object.assign({}, t), { target: e, url: u })
              )
            )
          }),
          (up.extract = function (e, u, t) {
            return (
              up.migrate.deprecated(
                "up.extract(target, document)",
                "up.navigate(target, { document })"
              ),
              up.navigate(
                Object.assign(Object.assign({}, t), { target: e, document: u })
              )
            )
          }),
          (up.fragment.first = function (...e) {
            return (
              up.migrate.deprecated("up.fragment.first()", "up.fragment.get()"),
              up.fragment.get(...e)
            )
          }),
          (up.first = up.fragment.first),
          (up.migrate.preprocessRenderOptions = function (u) {
            e.isString(u.history) &&
              "auto" !== u.history &&
              (up.migrate.warn(
                "Passing a URL as { history } option is deprecated. Pass it as { location } instead."
              ),
              (u.location = u.history),
              (u.history = "auto"))
            for (let t of ["target", "origin"])
              e.isJQuery(u[t]) &&
                (up.migrate.warn(
                  "Passing a jQuery collection as { %s } is deprecated. Pass it as a native element instead.",
                  t
                ),
                (u[t] = up.element.get(u[t])))
            "auto" === u.fail &&
              (up.migrate.warn(
                "The option { fail: 'auto' } is deprecated. Omit the option instead."
              ),
              delete u.fail)
            let t = e.pluckKey(u, "solo")
            e.isString(t)
              ? (up.migrate.warn(
                  "The up.render() option { solo } has been replaced with { abort } and { abort } no longer accepts a URL pattern. Check if you can use { abort: 'target'} or use up.network.abort(pattern) instead."
                ),
                (u.abort = (e) => up.network.abort(t, e)))
              : e.isFunction(t)
              ? (up.migrate.warn(
                  "The up.render() option { solo } has been replaced with { abort } and { abort } no longer accepts a Function(up.Request): boolean. Check if you can use { abort: 'target'} or use up.network.abort(fn) instead."
                ),
                (u.abort = (e) => {
                  up.network.abort(t, e)
                }))
              : !0 === t
              ? (up.migrate.deprecated(
                  "Option { solo: true }",
                  "{ abort: 'all' }"
                ),
                (u.abort = "all"))
              : !1 === t &&
                (up.migrate.deprecated(
                  "Option { solo: false }",
                  "{ abort: false }"
                ),
                up.migrate.warn(
                  "Unpoly 3+ only aborts requests targeting the same fragment. Setting { solo: false } may no longer be necessary."
                ),
                (u.abort = !1)),
              up.migrate.fixKey(u, "keep", "useKeep"),
              up.migrate.fixKey(u, "hungry", "useHungry"),
              up.migrate.fixKey(u, "failOnFinished", "onFailFinished"),
              e.isString(u.reveal)
                ? (up.migrate.deprecated(
                    `Option { reveal: '${u.reveal}' }`,
                    `{ scroll: '${u.reveal}' }`
                  ),
                  (u.scroll = u.reveal))
                : !0 === u.reveal
                ? (up.migrate.deprecated(
                    "Option { reveal: true }",
                    "{ scroll: 'target' }"
                  ),
                  (u.scroll = "target"))
                : !1 === u.reveal &&
                  (up.migrate.deprecated(
                    "Option { reveal: false }",
                    "{ scroll: false }"
                  ),
                  (u.scroll = !1)),
              !0 === u.resetScroll &&
                (up.migrate.deprecated(
                  "Option { resetScroll: true }",
                  "{ scroll: 'reset' }"
                ),
                (u.scroll = "reset")),
              !1 === u.resetScroll &&
                (up.migrate.deprecated(
                  "Option { resetScroll: false }",
                  "{ scroll: false }"
                ),
                (u.scroll = !1)),
              !0 === u.restoreScroll &&
                (up.migrate.deprecated(
                  "Option { restoreScroll: true }",
                  "{ scroll: 'restore' }"
                ),
                (u.scroll = "restore")),
              !1 === u.restoreScroll &&
                (up.migrate.deprecated(
                  "Option { restoreScroll: false }",
                  "{ scroll: false }"
                ),
                (u.scroll = !1))
          }),
          (up.migrate.postprocessReloadOptions = function (e) {
            var u
            let t,
              p =
                null === (u = e.headers) || void 0 === u
                  ? void 0
                  : u["If-Modified-Since"]
            ;(t = p ? Math.floor(0.001 * new Date(p)).toString() : "0"),
              (e.headers[up.protocol.headerize("reloadFromTime")] = t)
          }),
          (up.migrate.resolveOrigin = function (e, { origin: u } = {}) {
            return e.replace(/"[^"]*"|'[^']*'|&|:origin\b/g, function (t) {
              return ":origin" !== t && "&" !== t
                ? t
                : ("&" === t &&
                    up.migrate.deprecated("Origin shorthand '&'", ":origin"),
                  u
                    ? up.fragment.toTarget(u)
                    : void up.fail(
                        'Missing { origin } element to resolve "%s" reference (found in %s)',
                        t,
                        e
                      ))
            })
          }),
          up.migrate.removedEvent("up:fragment:kept", "up:fragment:keep"),
          up.fragment.config.patch(function () {
            ;(this.runScriptsValue = this.runScripts),
              (this.runScriptsSet = !1),
              Object.defineProperty(this, "runScripts", {
                get() {
                  return this.runScriptsValue
                },
                set(e) {
                  ;(this.runScriptsValue = e), (this.runScriptsSet = !0)
                },
              })
          }),
          up.on("up:framework:boot", function () {
            up.fragment.config.runScriptsSet ||
              up.migrate.warn(
                "Scripts within fragments are now executed. Configure up.fragment.config.runScripts to remove this warning."
              )
          }),
          up.compiler("[up-keep]", function (u) {
            let t = up.element.booleanOrStringAttr(u, "up-keep")
            e.isString(t) &&
              (up.migrate.warn(
                'The [up-keep] attribute no longer supports a selector value. Elements will be matched by their derived target. You may prevent keeping with [up-on-keep="if(condition) event.preventDefault()"]. '
              ),
              up.element.setMissingAttr(
                u,
                "up-on-keep",
                `if (!newFragment.matches(${JSON.stringify(
                  t
                )})) event.preventDefault()`
              ),
              u.setAttribute("up-keep", ""))
          })
      },
      234: () => {
        up.migrate.renamedEvent("up:app:booted", "up:framework:booted")
      },
      869: () => {
        up.history.config.patch(function (e) {
          up.migrate.renamedProperty(e, "popTargets", "restoreTargets")
        }),
          (up.history.url = function () {
            return (
              up.migrate.deprecated("up.history.url()", "up.history.location"),
              up.history.location
            )
          }),
          up.migrate.renamedEvent("up:history:push", "up:location:changed"),
          up.migrate.renamedEvent("up:history:pushed", "up:location:changed"),
          up.migrate.renamedEvent("up:history:restore", "up:location:changed"),
          up.migrate.renamedEvent("up:history:restored", "up:location:changed"),
          up.migrate.renamedEvent("up:history:replaced", "up:location:changed"),
          up.migrate.removedEvent("up:fragment:kept", "up:fragment:keep"),
          up.history.config.patch(function () {
            ;(this.updateMetaTagsValue = this.updateMetaTags),
              (this.updateMetaTagsSet = !1),
              Object.defineProperty(this, "updateMetaTags", {
                get() {
                  return this.updateMetaTagsValue
                },
                set(e) {
                  ;(this.updateMetaTagsValue = e), (this.updateMetaTagsSet = !0)
                },
              })
          }),
          up.on("up:framework:boot", function () {
            up.history.config.updateMetaTagsSet ||
              up.migrate.warn(
                "Meta tags in the <head> are now updated automatically. Configure up.history.config.updateMetaTags to remove this warning."
              )
          }),
          (up.migrate.warnOfHungryMetaTags = function (e) {
            let u = up.radio.config.selector("hungrySelectors"),
              t = up.util.filter(e, (e) => e.matches(u))
            t.length &&
              up.migrate.warn(
                "Meta tags in the <head> are now updated automatically. Remove the [up-hungry] attribute from %o.",
                t
              )
          })
      },
      905: () => {
        ;(up.migrate.handleLayerOptions = function (e) {
          up.migrate.fixKey(e, "flavor", "mode"),
            up.migrate.fixKey(e, "closable", "dismissable"),
            up.migrate.fixKey(e, "closeLabel", "dismissLabel")
          for (let u of ["width", "maxWidth", "height"])
            e[u] &&
              up.migrate.warn(
                `Layer option { ${u} } has been removed. Use { size } or { class } instead.`
              )
          e.sticky &&
            up.migrate.warn(
              "Layer option { sticky } has been removed. Give links an [up-peel=false] attribute to prevent layer dismissal on click."
            ),
            e.template &&
              up.migrate.warn(
                "Layer option { template } has been removed. Use { class } or modify the layer HTML on up:layer:open."
              ),
            "page" === e.layer &&
              (up.migrate.warn(
                "Option { layer: 'page' } has been renamed to { layer: 'root' }."
              ),
              (e.layer = "root")),
            ("modal" !== e.layer && "popup" !== e.layer) ||
              (up.migrate.warn(
                `Option { layer: '${e.layer}' } has been removed. Did you mean { layer: 'overlay' }?`
              ),
              (e.layer = "overlay"))
        }),
          (up.migrate.handleTetherOptions = function (e) {
            const [u, t] = e.position.split("-")
            t &&
              (up.migrate.warn(
                "The position value %o is deprecated. Use %o instead.",
                e.position,
                { position: u, align: t }
              ),
              (e.position = u),
              (e.align = t))
          }),
          (up.migrate.registerLayerCloser = (e) =>
            e.registerClickCloser("up-close", (u, t) => {
              up.migrate.deprecated("[up-close]", "[up-dismiss]"),
                e.dismiss(u, t)
            })),
          (up.migrate.handleLayerConfig = (e) =>
            up.migrate.fixKey(e, "historyVisible", "history")),
          Object.defineProperty(up.Layer.prototype, "historyVisible", {
            get: function () {
              return (
                up.migrate.deprecated(
                  "up.Layer#historyVisible",
                  "up.Layer#history"
                ),
                this.history
              )
            },
          })
      },
      969: () => {
        const e = up.link.config.selectorFn("followSelectors"),
          u = up.link.config.selectorFn("preloadSelectors")
        let t
        up.migrate.renamedAttribute("up-flavor", "up-mode", { scope: e }),
          up.migrate.renamedAttribute("up-closable", "up-dismissable", {
            scope: e,
          }),
          up.migrate.removedAttribute("up-width", {
            scope: e,
            replacement: "[up-size] or [up-class]",
          }),
          up.migrate.removedAttribute("up-height", {
            scope: e,
            replacement: "[up-size] or [up-class]",
          }),
          up.migrate.renamedAttribute("up-history-visible", "up-history", {
            scope: e,
          }),
          up.migrate.renamedAttribute("up-clear-cache", "up-expire-cache", {
            scope: e,
          }),
          up.migrate.transformAttribute("up-solo", function (e, u) {
            switch (u) {
              case "":
                up.migrate.warn(
                  'Attribute [up-solo] has been replaced with [up-abort="all"]'
                ),
                  e.setAttribute("up-abort", "all")
                break
              case "true":
                up.migrate.warn(
                  'Attribute [up-solo="true"] has been replaced with [up-abort="all"]'
                ),
                  e.setAttribute("up-abort", "all")
                break
              case "false":
                up.migrate.warn(
                  'Attribute [up-solo="false"] has been replaced with [up-abort="false"]'
                ),
                  up.migrate.warn(
                    'Unpoly 3+ only aborts requests targeting the same fragment. Setting [up-solo="false"] may no longer be necessary.'
                  ),
                  e.setAttribute("up-abort", "false")
                break
              default:
                up.migrate.warn(
                  'Attribute [up-solo] has been renamed to [up-abort] and [up-abort] no longer accepts a URL pattern. Check if you can use [up-abort="target"] instead.'
                ),
                  e.setAttribute("up-abort", "target")
            }
          }),
          up.migrate.transformAttribute("up-reveal", function (e, u) {
            switch (u) {
              case "":
                up.migrate.warn(
                  'Attribute [up-reveal] has been replaced with [up-scroll="target"]'
                ),
                  e.setAttribute("up-scroll", "target")
                break
              case "true":
                up.migrate.warn(
                  'Attribute [up-reveal="true"] has been replaced with [up-scroll="target"]'
                ),
                  e.setAttribute("up-scroll", "target")
                break
              case "false":
                up.migrate.warn(
                  'Attribute [up-reveal="false"] has been replaced with [up-scroll="false"]'
                ),
                  e.setAttribute("up-scroll", "false")
                break
              default:
                up.migrate.warn(
                  'Attribute [up-reveal="%s"] has been replaced with [up-scroll="%s"]',
                  u
                )
            }
          }),
          up.migrate.transformAttribute("up-reset-scroll", function (e, u) {
            switch (u) {
              case "":
                up.migrate.warn(
                  'Attribute [up-reset-scroll] has been replaced with [up-scroll="reset"]'
                ),
                  e.setAttribute("up-scroll", "reset")
                break
              case "true":
                up.migrate.warn(
                  'Attribute [up-reset-scroll="true"] has been replaced with [up-scroll="reset"]'
                ),
                  e.setAttribute("up-scroll", "reset")
                break
              case "false":
                up.migrate.warn(
                  'Attribute [up-reset-scroll="false"] has been replaced with [up-scroll="false"]'
                ),
                  e.setAttribute("up-scroll", "false")
            }
          }),
          up.migrate.transformAttribute("up-restore-scroll", function (e, u) {
            switch (u) {
              case "":
                up.migrate.warn(
                  'Attribute [up-restore-scroll] has been replaced with [up-scroll="restore"]'
                ),
                  e.setAttribute("up-scroll", "restore")
                break
              case "true":
                up.migrate.warn(
                  'Attribute [up-restore-scroll="true"] has been replaced with [up-scroll="restore"]'
                ),
                  e.setAttribute("up-scroll", "restore")
                break
              case "false":
                up.migrate.warn(
                  'Attribute [up-restore-scroll="false"] has been replaced with [up-scroll="false"]'
                ),
                  e.setAttribute("up-scroll", "false")
            }
          }),
          up.migrate.targetMacro(
            "up-dash",
            { "up-preload": "", "up-instant": "" },
            () =>
              up.migrate.deprecated(
                "[up-dash]",
                "up.link.config.instantSelectors and up.link.config.preloadSelectors"
              )
          ),
          up.migrate.renamedAttribute("up-delay", "up-preload-delay", {
            scope: u,
          }),
          up.link.config.patch(function (e) {
            ;(e.preloadEnabled = !0),
              (t = up.migrate.removedProperty(
                e,
                "preloadEnabled",
                "The configuration up.link.config.preloadEnabled has been removed. To disable preloading, prevent up:link:preload instead."
              ))
          }),
          up.on("up:link:preload", function (e) {
            t[0] || e.preventDefault()
          })
        const p =
          "[up-href]:not([up-follow], [up-poll], [up-defer], [up-expand])"
        up.link.config.patch(function (e) {
          e.clickableSelectors.push(p), e.followSelectors.push(p)
        }),
          up.compiler(p, function (e) {
            up.migrate.warn(
              "Following links with only [up-href] has been deprecated. You must now also set an [up-follow] on the same link (found in %o).",
              e
            )
          })
        const o = `[up-instant]:is(a[href], ${p})`
        up.link.config.patch(function (e) {
          e.followSelectors.push(o)
        }),
          up.compiler(p, function (e) {
            up.migrate.warn(
              "Following links with only [up-instant] has been deprecated. You must now also set an [up-follow] on the same link (found in %o).",
              e
            )
          })
      },
      992: () => {
        const e = up.util
        up.migrate = (function () {
          const u = new up.Config(() => ({ logLevel: "warn" }))
          function t(u, ...t) {
            let p = e.extractCallback(t),
              { scope: o } = e.extractOptions(t),
              n = o || `[${u}]`
            up.macro(n, { priority: -1e3 }, function (e) {
              if (e.hasAttribute(u)) {
                let t = e.getAttribute(u)
                p(e, t)
              }
            })
          }
          const p = {},
            o = {}
          function n(e) {
            let u = p[e]
            if (u) return a(`Event ${e} has been renamed to ${u}`), u
            if (e in o) {
              let u = `Event ${e} has been removed`,
                t = o[e]
              return t && (u += `. Use ${t} instead.`), a(u), e
            }
            return e
          }
          const r = {},
            a = up.mockable((t, ...p) => {
              const o = e.sprintf(t, ...p)
              r[o] ||
                ((r[o] = !0), up.log[u.logLevel]("unpoly-migrate", t, ...p))
            }),
            i = [
              "top",
              "right",
              "bottom",
              "left",
              "padding",
              "padding-top",
              "padding-right",
              "padding-bottom",
              "padding-left",
              "margin",
              "margin-top",
              "margin-right",
              "margin-bottom",
              "margin-left",
              "border-width",
              "border-top-width",
              "border-right-width",
              "border-bottom-width",
              "border-left-width",
              "width",
              "height",
              "max-width",
              "max-height",
              "min-width",
              "min-height",
            ]
          function s(u) {
            return /[A-Z]/.test(u)
              ? (a(
                  `CSS property names must be in kebab-case, but got camelCase "${u}"`
                ),
                e.camelToKebabCase(u))
              : u
          }
          function l(e, u, t) {
            return !t && i.includes(e) && /^[\d.]+$/.test(u)
              ? (a(
                  `CSS length values must have a unit, but got "${e}: ${u}". Use "${e}: ${u}px" instead.`
                ),
                u + "px")
              : u
          }
          return {
            deprecated: function (e, u) {
              a(`${e} has been deprecated. Use ${u} instead.`)
            },
            renamedPackage: function (e, u) {
              Object.defineProperty(up, e, {
                get: () => (a(`up.${e} has been renamed to up.${u}`), up[u]),
              })
            },
            renamedProperty: function (u, t, p, o) {
              const n = e.memoize(() =>
                o
                  ? a(o)
                  : a(
                      "Property { %s } has been renamed to { %s } (found in %o)",
                      t,
                      p,
                      u
                    )
              )
              Object.defineProperty(u, t, {
                get() {
                  return n(), this[p]
                },
                set(e) {
                  n(), (this[p] = e)
                },
              })
            },
            removedProperty: function (u, t, p) {
              const o = e.memoize(() =>
                p
                  ? a(p)
                  : a(
                      "Property { %s } has been removed without replacement (found in %o)",
                      t,
                      u
                    )
              )
              let n = [u[t]]
              return (
                Object.defineProperty(u, t, {
                  get: () => (o(), n[0]),
                  set(e) {
                    o(), (n[0] = e)
                  },
                }),
                n
              )
            },
            forbiddenPropertyValue: function (e, u, t, p) {
              let o = e[u]
              Object.defineProperty(e, u, {
                get: () => o,
                set(e) {
                  if (e === t) throw new Error(p)
                  o = e
                },
              })
            },
            transformAttribute: t,
            renamedAttribute: function (u, p, { scope: o, mapValue: n } = {}) {
              t(u, { scope: o }, function (t, o) {
                a(
                  "Attribute [%s] has been renamed to [%s] (found in %o)",
                  u,
                  p,
                  t
                ),
                  n && (o = e.evalOption(n, o)),
                  t.setAttribute(p, o)
              })
            },
            removedAttribute: function (e, { scope: u, replacement: p } = {}) {
              t(e, { scope: u }, function (u, t) {
                p
                  ? a(
                      "Attribute [%s] has been removed (found in %o). Use %s instead.",
                      e,
                      u,
                      p
                    )
                  : a(
                      "Attribute [%s] has been removed without replacement (found in %o)",
                      e,
                      u
                    )
              })
            },
            formerlyAsync: function (e) {
              const u = Promise.resolve(),
                t = u.then
              return (
                (u.then = function () {
                  return (
                    a(`${e} no longer returns a promise`),
                    t.apply(this, arguments)
                  )
                }),
                u
              )
            },
            renamedEvent: function (e, u) {
              p[e] = u
            },
            removedEvent: function (e, u = null) {
              o[e] = u
            },
            fixStyleProps: function (u, t) {
              let p
              if (e.isString(u)) p = s(u)
              else if (e.isArray(u)) p = u.map(s)
              else if (e.isObject(u)) {
                p = {}
                for (let e in u) {
                  let o = u[e]
                  ;(e = s(e)), (o = l(e, o, t)), (p[e] = o)
                }
              }
              return p
            },
            fixEventTypes: function (u) {
              return e.uniq(e.map(u, n))
            },
            fixKey: function (u, t, p) {
              e.isDefined(u[t]) &&
                (a(
                  "Property { %s } has been renamed to { %s } (found in %o)",
                  t,
                  p,
                  u
                ),
                e.renameKey(u, t, p))
            },
            warn: a,
            loaded: !0,
            config: u,
          }
        })()
      },
      413: () => {
        const e = new Error(
          "up.modal.flavors has been removed without direct replacement. You may give new layers a { class } or modify layer elements on up:layer:open."
        )
        ;(up.modal = {
          visit: (e, u = {}) => (
            up.migrate.deprecated(
              "up.modal.visit(url)",
              'up.layer.open({ url, mode: "modal" })'
            ),
            up.layer.open(
              Object.assign(Object.assign({}, u), { url: e, mode: "modal" })
            )
          ),
          follow: (e, u = {}) => (
            up.migrate.deprecated(
              "up.modal.follow(link)",
              'up.follow(link, { layer: "modal" })'
            ),
            up.follow(
              e,
              Object.assign(Object.assign({}, u), { layer: "modal" })
            )
          ),
          extract: (e, u, t = {}) => (
            up.migrate.deprecated(
              "up.modal.extract(target, document)",
              'up.layer.open({ document, mode: "modal" })'
            ),
            up.layer.open(
              Object.assign(Object.assign({}, t), {
                target: e,
                html: u,
                layer: "modal",
              })
            )
          ),
          close: (e = {}) => (
            up.migrate.deprecated("up.modal.close()", "up.layer.dismiss()"),
            up.layer.dismiss(null, e),
            up.migrate.formerlyAsync("up.layer.dismiss()")
          ),
          url: () => (
            up.migrate.deprecated("up.modal.url()", "up.layer.location"),
            up.layer.location
          ),
          coveredUrl() {
            var e
            return (
              up.migrate.deprecated(
                "up.modal.coveredUrl()",
                "up.layer.parent.location"
              ),
              null === (e = up.layer.parent) || void 0 === e
                ? void 0
                : e.location
            )
          },
          get config() {
            return (
              up.migrate.deprecated("up.modal.config", "up.layer.config.modal"),
              up.layer.config.modal
            )
          },
          contains: (e) => (
            up.migrate.deprecated("up.modal.contains()", "up.layer.contains()"),
            up.layer.contains(e)
          ),
          isOpen: () => (
            up.migrate.deprecated("up.modal.isOpen()", "up.layer.isOverlay()"),
            up.layer.isOverlay()
          ),
          get flavors() {
            throw e
          },
          flavor() {
            throw e
          },
        }),
          up.migrate.renamedEvent("up:modal:open", "up:layer:open"),
          up.migrate.renamedEvent("up:modal:opened", "up:layer:opened"),
          up.migrate.renamedEvent("up:modal:close", "up:layer:dismiss"),
          up.migrate.renamedEvent("up:modal:closed", "up:layer:dismissed"),
          up.migrate.targetMacro("up-modal", { "up-layer": "new modal" }, () =>
            up.migrate.deprecated("[up-modal]", '[up-layer="new modal"]')
          ),
          up.migrate.targetMacro(
            "up-drawer",
            { "up-layer": "new drawer" },
            () =>
              up.migrate.deprecated("[up-drawer]", '[up-layer="new drawer"]')
          )
      },
      885: () => {
        const e = up.util
        up.migrate.renamedPackage("proxy", "network"),
          up.migrate.renamedEvent("up:proxy:load", "up:request:load"),
          up.migrate.renamedEvent("up:proxy:received", "up:request:loaded"),
          up.migrate.renamedEvent("up:proxy:loaded", "up:request:loaded"),
          up.migrate.renamedEvent("up:proxy:fatal", "up:request:offline"),
          up.migrate.renamedEvent("up:request:fatal", "up:request:offline"),
          up.migrate.renamedEvent("up:proxy:aborted", "up:request:aborted"),
          up.migrate.renamedEvent("up:proxy:slow", "up:network:late"),
          up.migrate.renamedEvent("up:proxy:recover", "up:network:recover"),
          up.migrate.renamedEvent("up:request:late", "up:network:late"),
          up.migrate.renamedEvent("up:request:recover", "up:network:recover"),
          up.network.config.patch(function (e) {
            const u = () =>
              up.migrate.deprecated(
                "up.proxy.config.preloadDelay",
                "up.link.config.preloadDelay"
              )
            Object.defineProperty(e, "preloadDelay", {
              get: () => (u(), up.link.config.preloadDelay),
              set(e) {
                u(), (up.link.config.preloadDelay = e)
              },
            })
          }),
          up.network.config.patch(function (e) {
            up.migrate.renamedProperty(e, "maxRequests", "concurrency"),
              up.migrate.renamedProperty(e, "slowDelay", "badResponseTime"),
              up.migrate.renamedProperty(
                e,
                "cacheExpiry",
                "cacheExpireAge",
                'The configuration up.network.config.cacheExpiry has been renamed to up.network.config.cacheExpireAge. Note that Unpoly 3+ automatically reloads cached content after rendering to ensure users always see fresh data ("cache revalidation"). Setting a custom expiry may no longer be necessary.'
              ),
              up.migrate.renamedProperty(e, "clearCache", "expireCache"),
              up.migrate.forbiddenPropertyValue(
                e,
                "cacheSize",
                0,
                "Disabling the cache with up.network.config.cacheSize = 0 is no longer supported. To disable automatic caching during navigation, set up.fragment.config.navigateOptions.cache = false instead."
              ),
              (up.network.config.requestMetaKeys = []),
              up.migrate.removedProperty(
                e,
                "requestMetaKeys",
                "The configuration up.network.config.requestMetaKeys has been removed. Servers that optimize responses based on request headers should instead set a Vary response header."
              )
          }),
          (up.migrate.handleRequestOptions = function (e) {
            up.migrate.fixKey(e, "clearCache", "expireCache"),
              e.solo &&
                up.migrate.warn(
                  "The option up.request({ solo }) has been removed. Use up.network.abort() or up.fragment.abort() instead."
                )
          }),
          (up.ajax = function (...e) {
            return (
              up.migrate.deprecated("up.ajax()", "up.request()"),
              up.request(...e).then((e) => e.text)
            )
          }),
          (up.network.clear = function () {
            up.migrate.deprecated("up.proxy.clear()", "up.cache.expire()"),
              up.cache.expire()
          }),
          (up.Request.Cache.prototype.clear = function (...e) {
            up.migrate.deprecated("up.cache.clear()", "up.cache.expire()"),
              this.expire(...e)
          }),
          (up.network.preload = function (...e) {
            return (
              up.migrate.deprecated(
                "up.proxy.preload(link)",
                "up.link.preload(link)"
              ),
              up.link.preload(...e)
            )
          }),
          (up.migrate.preprocessAbortArgs = function (u) {
            2 === u.length &&
              e.isString(u[1]) &&
              (up.migrate.warn(
                "up.network.abort() no longer takes a reason as a second argument. Pass it as { reason } option instead."
              ),
              (u[1] = { reason: u[1] }))
          }),
          (up.network.isIdle = function () {
            return (
              up.migrate.deprecated(
                "up.network.isIdle()",
                "!up.network.isBusy()"
              ),
              !up.network.isBusy()
            )
          }),
          (up.Request.prototype.navigate = function () {
            up.migrate.deprecated(
              "up.Request#navigate()",
              "up.Request#loadPage()"
            ),
              this.loadPage()
          }),
          up.migrate.renamedProperty(
            up.Request.prototype,
            "preload",
            "background"
          ),
          (up.Response.prototype.isSuccess = function () {
            return (
              up.migrate.deprecated(
                "up.Response#isSuccess()",
                "up.Response#ok"
              ),
              this.ok
            )
          }),
          (up.Response.prototype.getHeader = function (e) {
            return (
              up.migrate.deprecated(
                "up.Response#getHeader()",
                "up.Response#header()"
              ),
              this.header(e)
            )
          }),
          (up.Response.prototype.isError = function () {
            return (
              up.migrate.deprecated("up.Response#isError()", "!up.Response#ok"),
              !this.ok
            )
          })
        const u = up.network.config.progressBar
        up.network.config.patch(function (t) {
          t.progressBar = function () {
            return (function () {
              const u = up.EventListener.allNonDefault(document)
              return e.find(u, (e) => "up:network:late" === e.eventType)
            })()
              ? (up.migrate.warn(
                  "Disabled the default progress bar as may have built a custom loading indicator with your up:network:late listener. Please set up.network.config.progressBar to true or false."
                ),
                !1)
              : u
          }
        }),
          (up.network.shouldReduceRequests = function () {
            return (
              up.migrate(
                "up.network.shouldReduceRequests() has been removed without replacement"
              ),
              !1
            )
          }),
          up.network.config.patch(function (e) {
            up.migrate.removedProperty(e, "badRTT"),
              up.migrate.removedProperty(e, "badDownlink")
          })
      },
      69: () => {
        ;(up.popup = {
          attach: (e, u = {}) => (
            (e = up.fragment.get(e)),
            up.migrate.deprecated(
              "up.popup.attach(origin)",
              "up.layer.open({ origin, layer: 'popup' })"
            ),
            up.layer.open(
              Object.assign(Object.assign({}, u), { origin: e, layer: "popup" })
            )
          ),
          close: (e = {}) => (
            up.migrate.deprecated("up.popup.close()", "up.layer.dismiss()"),
            up.layer.dismiss(null, e),
            up.migrate.formerlyAsync("up.layer.dismiss()")
          ),
          url: () => (
            up.migrate.deprecated("up.popup.url()", "up.layer.location"),
            up.layer.location
          ),
          coveredUrl() {
            var e
            return (
              up.migrate.deprecated(
                "up.popup.coveredUrl()",
                "up.layer.parent.location"
              ),
              null === (e = up.layer.parent) || void 0 === e
                ? void 0
                : e.location
            )
          },
          get config() {
            return (
              up.migrate.deprecated("up.popup.config", "up.layer.config.popup"),
              up.layer.config.popup
            )
          },
          contains: (e) => (
            up.migrate.deprecated("up.popup.contains()", "up.layer.contains()"),
            up.layer.contains(e)
          ),
          isOpen: () => (
            up.migrate.deprecated("up.popup.isOpen()", "up.layer.isOverlay()"),
            up.layer.isOverlay()
          ),
          sync: () => (
            up.migrate.deprecated("up.popup.sync()", "up.layer.sync()"),
            up.layer.sync()
          ),
        }),
          up.migrate.renamedEvent("up:popup:open", "up:layer:open"),
          up.migrate.renamedEvent("up:popup:opened", "up:layer:opened"),
          up.migrate.renamedEvent("up:popup:close", "up:layer:dismiss"),
          up.migrate.renamedEvent("up:popup:closed", "up:layer:dismissed"),
          up.migrate.targetMacro("up-popup", { "up-layer": "new popup" }, () =>
            up.migrate.deprecated("[up-popup]", '[up-layer="new popup"]')
          )
      },
      677: () => {
        ;(up.migrate.clearCacheFromXHR = function (e) {
          let u = e.getResponseHeader("X-Up-Clear-Cache")
          if (u)
            return (
              up.migrate.deprecated("X-Up-Clear-Cache", "X-Up-Expire-Cache"),
              "false" !== u && u
            )
        }),
          (up.migrate.titleFromXHR = function (e) {
            let u = e.getResponseHeader("X-Up-Title")
            if (u) {
              if ("false" === u) return !1
              if ('"' !== u[0])
                return (
                  up.migrate.warn(
                    "X-Up-Title must now be a JSON-encoded string"
                  ),
                  u
                )
            }
          })
      },
      228: () => {
        const e = up.element
        let u
        up.radio.config.patch(function (e) {
          up.migrate.renamedProperty(e, "hungry", "hungrySelectors")
        }),
          (up.radio.config.pollEnabled = !0),
          up.radio.config.patch(function (e) {
            u = up.migrate.removedProperty(
              e,
              "pollEnabled",
              "The configuration up.radio.config.pollEnabled has been removed. To disable polling, prevent up:fragment:poll instead."
            )
          }),
          up.on("up:fragment:poll", function (e) {
            u[0] || e.preventDefault()
          }),
          up.compiler("[up-hungry][up-if-history]", function (u) {
            e.booleanAttr(u, "up-if-history") &&
              u.addEventListener("up:fragment:hungry", function (e) {
                e.renderOptions.history || e.preventDefault()
              })
          })
      },
      771: () => {
        const e = up.util,
          u = up.element
        up.migrate.renamedPackage("syntax", "script"),
          (up.migrate.postCompile = function (u, t) {
            let p
            if ((p = t.keep)) {
              up.migrate.warn(
                "The { keep: true } option for up.compiler() has been removed. Have the compiler set [up-keep] attribute instead."
              )
              const t = e.isString(p) ? p : ""
              for (let e of u) e.setAttribute("up-keep", t)
            }
          }),
          (up.migrate.targetMacro = function (t, p, o) {
            up.macro(`[${t}]`, function (n) {
              let r
              const a = e.copy(p)
              ;(r = n.getAttribute(t))
                ? (a["up-target"] = r)
                : (a["up-follow"] = ""),
                u.setMissingAttrs(n, a),
                n.removeAttribute(t),
                null == o || o()
            })
          }),
          (up.$compiler = function (...e) {
            up.migrate.warn(
              "up.$compiler() has been deprecated. Instead use up.compiler() with a callback that wraps the given native element in a jQuery collection."
            )
            let u = e.pop()
            return up.compiler(...e, function (e, ...t) {
              let p = jQuery(e)
              return u(p, ...t)
            })
          }),
          (up.$macro = function (...e) {
            up.migrate.warn(
              "up.$macro() has been deprecated. Instead use up.macro() with a callback that wraps the given native element in a jQuery collection."
            )
            let u = e.pop()
            return up.macro(...e, function (e, ...t) {
              let p = jQuery(e)
              return u(p, ...t)
            })
          }),
          (up.migrate.processCompilerPassMeta = function (e, u) {
            Object.defineProperty(e, "response", {
              get: () => (
                up.migrate.warn(
                  "Accessing meta.response from a compiler has been deprecated without replacement. Avoid fragments that compile differently for the initial page load vs. subsequent fragment updates."
                ),
                u
              ),
            })
          })
      },
      197: () => {
        up.macro("[up-tooltip]", function (e) {
          up.migrate.warn(
            "[up-tooltip] has been deprecated. A [title] was set instead."
          ),
            up.element.setMissingAttr(e, "title", e.getAttribute("up-tooltip"))
        })
      },
      480: () => {
        ;(up.util.only = function (e, ...u) {
          return (
            up.migrate.deprecated(
              "up.util.only(object, ...keys)",
              "up.util.pick(object, keys)"
            ),
            up.util.pick(e, u)
          )
        }),
          (up.util.except = function (e, ...u) {
            return (
              up.migrate.deprecated(
                "up.util.except(object, ...keys)",
                "up.util.omit(object, keys)"
              ),
              up.util.omit(e, u)
            )
          }),
          (up.util.parseUrl = function (...e) {
            return (
              up.migrate.deprecated("up.util.parseUrl()", "up.util.parseURL()"),
              up.util.parseURL(...e)
            )
          }),
          (up.util.any = function (...e) {
            return (
              up.migrate.deprecated("up.util.any()", "up.util.some()"),
              up.util.some(...e)
            )
          }),
          (up.util.all = function (...e) {
            return (
              up.migrate.deprecated("up.util.all()", "up.util.every()"),
              up.util.every(...e)
            )
          }),
          (up.util.detect = function (...e) {
            return (
              up.migrate.deprecated("up.util.detect()", "up.util.find()"),
              up.util.find(...e)
            )
          }),
          (up.util.select = function (...e) {
            return (
              up.migrate.deprecated("up.util.select()", "up.util.filter()"),
              up.util.filter(...e)
            )
          }),
          (up.util.setTimer = function (...e) {
            return (
              up.migrate.deprecated("up.util.setTimer()", "up.util.timer()"),
              up.util.timer(...e)
            )
          }),
          (up.util.escapeHtml = function (...e) {
            return (
              up.migrate.deprecated(
                "up.util.escapeHtml()",
                "up.util.escapeHTML()"
              ),
              up.util.escapeHTML(...e)
            )
          }),
          (up.util.selectorForElement = function (...e) {
            return (
              up.migrate.deprecated(
                "up.util.selectorForElement()",
                "up.fragment.toTarget()"
              ),
              up.fragment.toTarget(...e)
            )
          }),
          (up.util.nextFrame = function (...e) {
            return (
              up.migrate.deprecated("up.util.nextFrame()", "up.util.task()"),
              up.util.task(...e)
            )
          }),
          (up.util.times = function (e, u) {
            up.migrate.deprecated("up.util.times()", "a `for` loop")
            for (let t = 0; t < e; t++) u()
          }),
          (up.util.assign = function (...e) {
            return (
              up.migrate.deprecated("up.util.assign()", "Object.assign()"),
              Object.assign(...e)
            )
          }),
          (up.util.values = function (...e) {
            return (
              up.migrate.deprecated("up.util.values()", "Object.values()"),
              Object.values(...e)
            )
          }),
          (up.util.microtask = window.queueMicrotask)
      },
      338: () => {
        up.migrate.renamedPackage("layout", "viewport"),
          up.viewport.config.patch(function (e) {
            up.migrate.renamedProperty(e, "viewports", "viewportSelectors"),
              up.migrate.renamedProperty(e, "fixedTop", "fixedTopSelectors"),
              up.migrate.renamedProperty(
                e,
                "fixedBottom",
                "fixedBottomSelectors"
              ),
              up.migrate.renamedProperty(
                e,
                "anchoredRight",
                "anchoredRightSelectors"
              ),
              up.migrate.renamedProperty(e, "snap", "revealSnap"),
              up.migrate.removedProperty(e, "scrollSpeed")
          }),
          (up.viewport.closest = function (...e) {
            return (
              up.migrate.deprecated(
                "up.viewport.closest()",
                "up.viewport.get()"
              ),
              up.viewport.get(...e)
            )
          }),
          (up.viewport.scroll = function (e, u, t = {}) {
            return (
              up.migrate.deprecated("up.scroll()", "Element#scrollTo()"),
              (e = up.fragment.get(e, t)).scrollTo(
                Object.assign(Object.assign({}, t), { top: u })
              ),
              up.migrate.formerlyAsync("up.scroll()")
            )
          }),
          (up.scroll = up.viewport.scroll)
      },
    },
    u = {}
  function t(p) {
    var o = u[p]
    if (void 0 !== o) return o.exports
    var n = (u[p] = { exports: {} })
    return e[p](n, n.exports, t), n.exports
  }
  up.framework.startExtension(),
    t(992),
    t(390),
    t(480),
    t(937),
    t(294),
    t(700),
    t(394),
    t(771),
    t(305),
    t(354),
    t(234),
    t(869),
    t(729),
    t(969),
    t(905),
    t(413),
    t(69),
    t(197),
    t(677),
    t(885),
    t(228),
    t(338),
    up.framework.stopExtension()
})()
