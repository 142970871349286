import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["img", "input"]
  imgTarget: HTMLImageElement
  inputTarget: HTMLInputElement

  changePreview() {
    const fileReader = new FileReader()
    fileReader.onload = (e) => {
      this.imgTarget.src = e.target.result as string
    }
    fileReader.readAsDataURL(this.inputTarget.files[0])
  }
}
