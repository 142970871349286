/**
 * Find all users avatars on the current page.
 */
function allUserAvatars() {
  return $(`.avatar[data-user-id]`)
}

/**
 * Find the avatars for the user with the given ID. This expects `.avatar` elements
 * to have a `data-user-id` attribute with the `userId` value.
 *
 * @param {string | number} userId
 * @return jQuery
 */
function userAvatars(userId) {
  return allUserAvatars().filter(`[data-user-id="${userId}"]`)
}

/**
 * Mark avatars on the page for the user identifier by `userId` as online using
 * the `avatar--online` class.
 *
 * @param {string | number} userId
 */
export function markOnline(userId) {
  userAvatars(userId).removeClass("avatar--offline").addClass("avatar--online")
}

/**
 * Mark avatars on the page for the user identifier by `userId` as offline using
 * the `avatar--offline` class.
 *
 * @param {string | number} userId
 */
export function markOffline(userId) {
  userAvatars(userId).removeClass("avatar--online").addClass("avatar--offline")
}
