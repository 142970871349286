import { Controller } from "@hotwired/stimulus"
import * as toastr from "toastr"

export default class extends Controller {
  declare dragitemId: string
  declare folderid: string

  onDragStart(event) {
    const dragitemid = $(event.target).closest("div").data("dragitem-id")
    this.dragitemId = dragitemid
    event.dataTransfer.effectAllowed = "move"
  }

  onDragEnter(event) {
    $("div[data-folderid]").each(function () {
      $(this).removeClass("opacity-100")
    })

    $(event.target).closest("div[data-folderid]").addClass("opacity-100")

    if (event.preventDefault) {
      event.preventDefault()
    }

    return false
  }

  onDragOver(event) {
    if (event.preventDefault) {
      event.preventDefault()
    }
    return false
  }

  onDrop(event) {
    event.preventDefault()
    this.folderid = $(event.target).closest("div").data("folderid")
  }

  submit(event, data) {
    $.ajax({
      url: this.data.get("endpoint"),
      type: "post",
      data: data,
    })
      .done(function () {
        if (typeof data.folder_id !== "undefined") {
          toastr.success("Item successfully moved into folder.")
          $(event.target).closest("[draggable='true']").addClass("hidden")
        }
      })
      .fail(function () {
        toastr.error("Unable to move item")
      })
  }

  onDragEnd(event) {
    const itemData = this.dragitemId.split("-")

    this.submit(event, {
      folder_id: this.folderid,
      item_class: itemData[0],
      item_id: itemData[1],
    })
  }
}
