import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["questiongroup"]

  declare readonly questiongroupTarget: Element

  toggle() {
    this.element.classList.toggle("is-open")
  }

  showHidden() {
    $(this.questiongroupTarget).removeClass("hidden")
  }
}
