import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["password", "passwordConfirmation"]
  passwordTarget: HTMLInputElement
  passwordConfirmationTarget: HTMLInputElement

  checkPasswordFormat() {
    const valid = this.checkFormat(this.passwordTarget)

    if (valid) {
      $("#pswd_info").hide()
    } else {
      $("#pswd_info").show()
    }
  }

  checkPasswordConfirmationFormat() {
    const valid = this.checkFormat(this.passwordConfirmationTarget)

    if (valid) {
      $("#pswd_info").hide()
    } else {
      $("#pswd_info").show()
    }
  }

  private checkFormat(target) {
    let valid = true

    //validate letter
    if (target.value.match(/[A-z]/)) {
      $("#letter").removeClass("invalid").addClass("valid")
    } else {
      $("#letter").removeClass("valid").addClass("invalid")
      valid = false
    }

    //validate capital letter
    if (target.value.match(/[A-Z]/)) {
      $("#capital").removeClass("invalid").addClass("valid")
    } else {
      $("#capital").removeClass("valid").addClass("invalid")
      valid = false
    }

    //validate number
    if (target.value.match(/\d/)) {
      $("#number").removeClass("invalid").addClass("valid")
    } else {
      $("#number").removeClass("valid").addClass("invalid")
      valid = false
    }

    // validate length
    if (target.value.length >= 8) {
      $("#length").removeClass("invalid").addClass("valid")
    } else {
      $("#length").removeClass("valid").addClass("invalid")
      valid = false
    }

    // validate special character
    // eslint-disable-next-line
    if (target.value.match(/[@$!%*?&#^\=\(\)\-\_]/)) {
      $("#special").removeClass("invalid").addClass("valid")
    } else {
      $("#special").removeClass("valid").addClass("invalid")
      valid = false
    }

    if (
      this.passwordTarget.value.length >= 8 &&
      this.passwordConfirmationTarget.value.length >= 8
    ) {
      if (this.passwordTarget.value === this.passwordConfirmationTarget.value) {
        $("#match").removeClass("invalid").addClass("valid")
      } else {
        $("#match").removeClass("valid").addClass("invalid")
        valid = false
      }
    }

    return valid
  }
}
