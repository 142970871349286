import introJS from "intro.js"
import $ from "jquery"

$(function () {
  if (
    window.location.pathname == "/en/people_analytics" ||
    window.location.pathname == "/nl/people_analytics"
  ) {
    startTour()
    $("#help-button").on("click", () => {
      startTour(true)
    })
  }
})

function startTour(restart = false) {
  var cookieName = "people-analytics-tour"
  var intro = introJS()
    .onexit(explainHelpButton)
    .setOptions({
      showBullets: false,
      showProgress: true,
      disableInteraction: true,
      dontShowAgainCookie: cookieName,
    })
    .addSteps(getSteps())
  if (document.cookie.indexOf(cookieName) === -1 || restart) {
    intro.setDontShowAgain(true).start()
  }
}

function explainHelpButton() {
  introJS()
    .setOptions({
      showBullets: false,
      doneLabel: "OK",
      steps: [
        {
          element: "#help-button",
          title: "Need help?",
          intro:
            "You can always show the tour again by clicking this <b>help</b> button.",
          position: "bottom-right-aligned",
        },
      ],
    })
    .start()
}

function getSteps() {
  return [
    {
      title: "Hello!",
      intro:
        "Welcome to <b>People Analytics</b>.<br /><br />Let's take a quick tour to show you how to use this amazing feature.<br /><br />Click <b>next</b> when you are ready to start the tour.",
    },
    {
      element: ".intro-client-overview",
      title: "Client overview",
      intro:
        "Here you will find an <b>overview</b> of all <b>questionnaires</b> with your clients.",
      position: "bottom-middle-aligned",
    },
    {
      element: "#intro-program-filter",
      title: "Filter",
      intro:
        "You can filter the list of clients by selecting a <b>program</b> from this dropdown.",
    },
    {
      element: ".intro-client-progress div",
      title: "Client progress",
      intro:
        "Here you will find the <b>progress</b> of the coaching.<br /><br />Each circle represents a measure moment.<br /><br />When a client submits a questionnaire the circle will change to show the progress of the client.",
      position: "bottom-middle-aligned",
      highlightClass: "-ml-2",
    },
    {
      element: ".legend",
      title: "Questionnaire progress",
      intro:
        "Here you will find the different states a questionnaire can have.",
      position: "top-middle-aligned",
    },
    {
      element: ".peer-count",
      title: "Respondents",
      intro:
        "Here you will find the <b>respondents</b> for this client.<br /><br />When the icon is green you can <b>add respondents</b> by clicking on the icon.",
      position: "bottom-middle-aligned",
    },
    {
      element: ".actions-dropdown",
      title: "Actions",
      intro:
        "The action menu allows you to <b>send the questionnaire</b> to the client and <b>create a report (PDF)</b>.<br /><br />You can send the questionnaires to your clients via <b>email</b>, via an UNLOQ campus <b>dialog message</b> or you can <b>copy the link</b> and send the link to client via a different method.",
      position: "bottom-right-aligned",
    },
    {
      element: ".intro-client-progress div",
      title: "Respondent progress",
      intro:
        "You can click on this progress indicator if you want to see the <b>progress of each individual respondent</b>.",
      position: "bottom-middle-aligned",
      highlightClass: "-ml-2",
    },
    {
      title: "Good luck!",
      intro:
        'Thank you for taking this tour!<br /><br />We hope you will enjoy this feature as much as we do!<br /><br />If you have any questions, suggestions or complaints, feel free to <b><a target="_blank" href="/en/pages/support">contact us</a></b>.<br /><br />Kind regards,<br /><i>The UNLOQ Campus team</i>',
    },
  ]
}
