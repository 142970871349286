import { Controller } from "@hotwired/stimulus"
import { submitFilterForm } from "helpers/form_helper"

export default class extends Controller {
  connect() {
    $(".select2").on("select2:select", function () {
      submitFilterForm()
    })
  }

  submit() {
    submitFilterForm()
  }
}
