import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]
  modalTarget: HTMLElement

  dismiss() {
    this.modalTarget.remove()
  }
}
