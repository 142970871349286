import { Controller } from "@hotwired/stimulus"

function setCookie(cname, cvalue, exdays, path) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = "expires=" + d.toUTCString()
  document.cookie = cname + "=" + cvalue + "; " + expires + ";path=" + path
}

/**
 * Allow a banner on the page to be dismissed, with the dismissal being stored in a cookie
 * so it does not show up again.
 *
 * Example usage:
 *
 *     <div data-controller="banner" data-banner-name="cookies">
 *       We use cookies.
 *       <div data-action="click->banner#dismiss">close</div>
 *     </div>
 *
 */
export default class BannerController extends Controller {
  connect() {
    if (document.cookie.indexOf(this.data.get("name")) === -1) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    this.element.classList.remove("hidden")
  }

  hide() {
    this.element.classList.add("hidden")
  }

  dismiss() {
    setCookie(this.data.get("name"), 1, 1825, "/")
    $(this.element).slideUp(200, () => this.hide())
  }
}
