import { Controller } from "@hotwired/stimulus"
import sortable from "html5sortable/dist/html5sortable.es.js"
import * as toastr from "toastr"

export default class extends Controller {
  connect() {
    sortable(this.element, {
      items: "[data-sortable-url]",
      forcePlaceholderSize: true,
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.element.addEventListener("sortupdate", (e: any) => {
      const item = e.detail.item
      const position = e.detail.destination.index + 1
      $.post(item.dataset.sortableUrl, { position })
        .done(() => toastr.success("Position updated"))
        .fail(() => toastr.error("Unable to update position"))
    })
  }
}
