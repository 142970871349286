import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $("#dialog-message-toggle").on("click", function () {
      let toggleValue

      if ($("#dialog-message-toggle").prop("checked")) {
        toggleValue = "true"
      } else {
        toggleValue = "false"
      }

      return $.ajax({
        url: $("#dialog-message-toggle").data("notifications-url"),
        method: "PATCH",
        data: {
          profile_attributes: { new_dialog_message_emails: toggleValue },
        },
      }).fail((e) => {
        console.error("Error when saving notification setting.", e)
      })
    })

    $("#comment-emails-toggle").on("click", function () {
      let toggleValue

      if ($("#comment-emails-toggle").prop("checked")) {
        toggleValue = "true"
      } else {
        toggleValue = "false"
      }

      return $.ajax({
        url: $("#comment-emails-toggle").data("notifications-url"),
        method: "PATCH",
        data: { profile_attributes: { new_comment_emails: toggleValue } },
      }).fail((e) => {
        console.error("Error when saving notification setting.", e)
      })
    })

    $("#people-analytics-toggle").on("click", function () {
      let toggleValue

      if ($("#people-analytics-toggle").prop("checked")) {
        toggleValue = "true"
      } else {
        toggleValue = "false"
      }

      return $.ajax({
        url: $("#people-analytics-toggle").data("notifications-url"),
        method: "PATCH",
        data: { profile_attributes: { people_analytics_emails: toggleValue } },
      }).fail((e) => {
        console.error("Error when saving notification setting.", e)
      })
    })
  }
}
