const availableEmoji = [
  "👏",
  "👍",
  "🙌",
  "💪",
  "🤘",
  "👌",
  "✊",
  "✨",
  "❤",
  "💯",
  "🎉",
  "🎊",
  "😊",
  "😀",
  "😂",
  "😅",
  "😎",
  "😉",
  "😜",
  "😬",
  "😮",
  "😳",
  "🤔",
  "😒",
  "😢",
  "😭",
  "😱",
  "👀",
  "🙏",
  "💩",
  "👎",
  "✌",
  "👈",
  "👆",
  "✋",
  "👋",
  "☀",
  "🌙",
  "💥",
  "🔥",
  "🎂",
  "🍴",
  "💰",
  "🥇",
  "🚨",
  "💡",
  "🛠",
  "📈",
  "✅",
  "📢",
]

export function emoji() {
  return availableEmoji.map((e) => `<span>${e}</span>`).join(" ")
}
