export function submitFilterForm() {
  if ($("#query_filter_q").length) {
    $("#filterform").append(
      '<input type="hidden" name="query_filter[q]" value="' +
        $("#query_filter_q").val() +
        '" />',
    )
  }
  $("#filterform").submit()
}
