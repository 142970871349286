import $ from "jquery"
import tippy from "tippy.js"

$(function () {
  loadTippy()
})

$(document).on("up:layer:open up:fragment:inserted", loadTippy)

function loadTippy() {
  tippy(".tippy", {
    placement: "right",
    arrow: true,
    animation: "fade",
    theme: "pluform",
    allowHTML: true,
  })
}
