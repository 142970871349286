import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "summary", "chevron"]
  contentTarget: HTMLElement
  summaryTarget: HTMLElement

  connect() {
    $(this.contentTarget).hide().removeClass("hidden")
  }

  toggle() {
    $(this.contentTarget).slideToggle(150)
    this.summaryTarget.classList.toggle("attachment-header--open")
  }
}
