import { Controller } from "@hotwired/stimulus"

/**
 * Given two target elements, allow swapping one with the other using
 * a simple animation. Useful for forms where you want to replace some
 * static fields with input fields, so the user can edit them.
 */
export default class extends Controller {
  static targets = ["a", "b"]
  declare readonly aTarget: HTMLElement
  declare readonly bTarget: HTMLElement

  connect() {
    $(this.aTarget).hide().removeClass("hidden")
  }

  swap(e) {
    e.preventDefault()
    const a = $(this.aTarget)
    const b = $(this.bTarget)

    if (b.is(":visible")) {
      b.slideUp(150, () => {
        a.slideDown(150)
      })
    } else {
      a.slideUp(150, () => {
        b.slideDown(150)
      })
    }
  }
}
