import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["role", "client", "sow", "training", "programid", "coaches"]
  roleTarget: HTMLInputElement
  clientTarget: HTMLInputElement
  sowTarget: HTMLInputElement
  trainingTarget: HTMLInputElement
  programidTarget: HTMLInputElement
  coachesTarget: HTMLInputElement

  changeRole() {
    if (this.roleTarget.value === "client") {
      $(this.clientTarget).prop("required", true)
      this.clientTarget.classList.remove("hidden")
      this.sowTarget.classList.add("hidden")
      this.trainingTarget.classList.add("hidden")
      $(this.coachesTarget).removeAttr("disabled")
    } else {
      $(this.clientTarget).prop("required", false)
      this.clientTarget.classList.add("hidden")
      this.sowTarget.classList.remove("hidden")
      this.trainingTarget.classList.remove("hidden")
      $(this.coachesTarget).attr("disabled", "true")
    }
  }

  changeProgram() {
    $.ajax({
      url: "/en/programs/" + this.programidTarget.value + "/coaches.json",
      success: (data) => {
        this.refreshDropdownValues(data)
      },
      error: function () {
        console.log("Error retrieving coaches")
      },
    })
  }

  refreshDropdownValues(data) {
    this.coachesTarget.innerHTML = ""
    for (let i = 0; i < data.length; i++) {
      const coach = data[i]
      this.coachesTarget.innerHTML +=
        '<option value="' + coach.id + '">' + coach.name + "</option>"
    }
  }
}
