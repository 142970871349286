import $ from "jquery"

$(function () {
  loadLanguageSwitch()
})

$(document).on("up:layer:open up:fragment:inserted", loadLanguageSwitch)

function loadLanguageSwitch() {
  $(".lang-switch").on("click", (e) => {
    const locale = $(e.target).attr("id")
    localStorage.setItem("RaStore.locale", locale)
  })
}
