import consumer from "./consumer"
import dialog from "../helpers/dialog_helper"

function updateDialogMessages(message) {
  dialog.collection.append(message)
  dialog.highlightMessages()
  dialog.scrollToBottom()
  dialog.updateLastSeenAt()
}

export default consumer.subscriptions.create("DialogChannel", {
  connected: function () {
    this.followCurrentDialog()
    this.installPageChangeCallback()
  },
  received: function (data) {
    if (data.message.includes(dialog.token)) {
      // double check permissions / correct dialogue
      updateDialogMessages(data.message)
    }
  },
  followCurrentDialog: function () {
    if (dialog.token) {
      this.perform("follow", { id: dialog.token })
    } else {
      this.perform("unfollow")
    }
  },
  installPageChangeCallback: function () {
    if (!this.installedPageChangeCallback) {
      this.installedPageChangeCallback = true
      $(document).on("DOMContentLoaded", this.followCurrentDialog.bind(this))
      $(document).on(
        "up:fragment:inserted",
        this.followCurrentDialog.bind(this)
      )
    }
  },
})
