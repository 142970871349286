import { Controller } from "@hotwired/stimulus"
import toastr from "toastr"

export default class extends Controller {
  static targets = ["maincoach"]

  declare readonly maincoachTarget: HTMLElement

  submit() {
    const form = $(this.maincoachTarget).closest("form")
    const row = $(this.maincoachTarget).closest(".accordion-header")
    const url = form.attr("action")

    $.ajax({
      type: "PATCH",
      url: url,
      data: form.serialize(),
      success: function () {
        row.fadeOut()
        row.next().fadeOut()
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      error: function (request, status, error) {
        toastr.error(request.responseText)
      },
    })
  }

  delete() {
    const form = $(this.maincoachTarget).closest("form")
    const row = $(this.maincoachTarget).closest(".accordion-header")
    const url = form.attr("action")

    $.ajax({
      type: "DELETE",
      url: url,
      data: form.serialize(),
      success: function () {
        row.fadeOut()
        row.next().fadeOut()
      },
    })
  }
}
