export function embedCode(id, url) {
  if (url.includes("vimeo")) {
    return `<iframe src="https://player.vimeo.com/video/${id}?autoplay=1" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`
  }

  if (url.includes("talks") && url.includes("ted")) {
    return `<iframe src="https://embed.ted.com/talks/${id}" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`
  }

  return `<iframe src="https://www.youtube-nocookie.com/embed/${id}" width="560" height="315" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
}

export function extractVideoId(url) {
  // eslint-disable-next-line
  const youtubeRegExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  // eslint-disable-next-line
  const vimeoRegExp = /^.*vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
  const tedxRegExp = /^.*ted\.com\/talks\/(.*)/;

  const youtubeMatch = url.match(youtubeRegExp)
  const vimeoMatch = url.match(vimeoRegExp)
  const tedxMatch = url.match(tedxRegExp)

  if (youtubeMatch && youtubeMatch[2].length == 11) {
    return youtubeMatch[2]
  } else if (vimeoMatch && vimeoMatch[3].length >= 3) {
    return vimeoMatch[3]
  } else if (tedxMatch && tedxMatch[1].length >= 3) {
    return tedxMatch[1]
  } else {
    return null
  }
}

export function previewUrl(url) {
  // eslint-disable-next-line
  const youtubeRegExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

  const match = url.match(youtubeRegExp)
  if (match && match[2].length == 11) {
    return `https://img.youtube.com/vi/${match[2]}/0.jpg`
  }

  return `/video.png`
}
