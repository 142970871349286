import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu"]
  menuTarget: HTMLElement

  toggleMenu() {
    $(this.menuTarget).toggle()
  }
}
