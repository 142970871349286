import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  get url() {
    return this.data.get("url")
  }

  click() {
    document.location = this.url
  }
}
