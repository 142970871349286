import consumer from "./consumer"
import { markOffline, markOnline } from "../helpers/presence_helper"

export default consumer.subscriptions.create("PresenceChannel", {
  received(data) {
    if (data === "ping") {
      this.perform("pong")
    } else if (data.type === "appear") {
      markOnline(data.id)
    } else if (data.type === "disappear") {
      markOffline(data.id)
    }
  },
})
