import { Controller } from "@hotwired/stimulus"
import dialog from "../helpers/dialog_helper"

export default class extends Controller {
  static targets = ["title"]

  titleTarget: HTMLInputElement

  connect() {
    dialog.highlightMessages()
    dialog.scrollToBottom()
    dialog.catchAndInlineEmbeds()
  }

  updateTitle() {
    const title = $(this.titleTarget).val()
    $("#loader").removeClass("hidden")

    $.ajax({
      type: "PATCH",
      url: `${document.location.href}/title`,
      data: { dialog: { title: title } },
    })
      .done(function () {
        $("#loader").addClass("hidden")
        $("#check").removeClass("hidden")
      })
      .fail((e) => {
        console.error("Error when saving dialog title", e)
      })
  }
}
