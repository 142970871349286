import $ from "jquery"

$(function () {
  loadArchivedProgramsToggler()
})

function loadArchivedProgramsToggler() {
  $(".archived-programs-toggler").on("click", (e) => {
    const id = $(e.target).attr("data-id")
    $(`#toggle-archived-programs-${id}`).toggleClass("hidden")
  })
}
